import { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import useApi from 'Lib/hooks/useApi';
import useNotify from 'Lib/hooks/useNotify';
import fileSizeValidator from 'Lib/utilities/fileSizeValidator';

import { deleteFileHandler, dropHandler } from './helpers';
import { ShowFiles } from './utils';

import 'Lib/scss/components/_uploadFiles.scss';
import 'Lib/scss/components/_fileManager.scss';

export const IMAGE_EXTENSIONS = ['.png', '.img', '.jpg', '.jpeg', '.heic', '.heif'];

export const UploadFiles = props => {
    const [fileIDs, setFileIDs] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [filePreviews, setFilePreviews] = useState([]);
    const [disableDropzone, setDisableDropzone] = useState(false);

    const onUploadFile = useApi(props.api.upload);
    const onDelete = useApi(props.api.delete);
    const config = props.config || {};

    const { toastError } = useNotify();
    const { maxFiles = 0 } = props;

    useEffect(() => {
        if (props.formObject && props.formObject.form) {
            props.formObject.form.setFieldValue(props.formObject.field, props.formObject.fieldType == 'string' ? fileIDs[0] : fileIDs);
        }

        if (maxFiles > 0) setDisableDropzone(fileIDs.length >= props.maxFiles);
    }, [fileIDs]);

    useEffect(() => {
        if (props.filePreviews) {
            props.formObject.form.setFieldValue(
                props.formObject.field,
                props.filePreviews.map(({ fileAttachment }) => fileAttachment.fileID)
            );
            setFileIDs(props.filePreviews.map(({ fileAttachment }) => fileAttachment.fileID));
            setFilePreviews(
                props.filePreviews.map(({ file }) => ({
                    filePreview: { ...file, name: file.title },
                    status: 'success',
                    percentage: 100
                }))
            );
        }
    }, [props.filePreviews]);

    if (!props.filePreviews && props.formObject) {
        useEffect(() => {
            if (!props.formObject.form.dirty) {
                setFileIDs([]);
                setFilePreviews([]);
                setFileNames([]);
            }
        }, [props.formObject.form.dirty]);
    }

    const handleDelete = (file, index) => {
        deleteFileHandler(file, index, {
            fileNames,
            fileIDs,
            filePreviews,
            config,
            setFileNames,
            setFileIDs,
            setFilePreviews,
            onDelete
        });
    };

    const onDrop = event => {
        if (maxFiles > 0 && fileIDs.length + event.length > maxFiles) return toastError(`You can only upload ${maxFiles} files`);

        dropHandler(
            event,
            {
                formObject: props.formObject,
                filePreviews,
                fileIDs,
                fileNames,
                config,
                callback: props.callback,
                onUploadFile,
                setFileNames,
                setFilePreviews,
                setFileIDs,
                notify: toast,
                fetchFiles: props.callback || null
            },
            toastError
        );
    };

    return (
        <>
            <Dropzone onDrop={onDrop} className="image-uploader" validator={fileSizeValidator} maxFiles={props.maxFiles} accept={props.accept} disabled={disableDropzone}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: 'dropzone' })} className="image-uploader">
                        {props.children ? (
                            props.children
                        ) : disableDropzone ? (
                            <div>File limit reached</div>
                        ) : (
                            <div>
                                {`Drag file${maxFiles === 1 ? '' : 's'} here to upload`}
                                <br />
                                (or click to open dialog)
                            </div>
                        )}
                        <input {...getInputProps()} />
                    </div>
                )}
            </Dropzone>
            <ShowFiles filePreviews={filePreviews} handleDelete={props.hideDelete == true ? null : handleDelete} />
        </>
    );
};

UploadFiles.propTypes = {
    formObject: PropTypes.shape({
        form: PropTypes.object,
        field: PropTypes.string
    })
};
