import { Component } from 'react';
import { components } from 'react-select';
import { default as ReactSelect } from 'react-select';

import Field from './Field';

const Option = props => (
    <components.Option {...props}>
        <input type="checkbox" disabled={props.isDisabled} checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
    </components.Option>
);

const MultiValueRemove = props => {
    if (props.data.isFixed) return null;
    return <components.MultiValueRemove {...props} />;
};

class ChecklistField extends Component {
    handleChange = (options, form, field) => {
        if (this.props.onChange) {
            this.props.onChange(options, form, field);
        }

        if (!options) {
            form.setFieldValue(field.name, null);
            return;
        }

        form.setFieldValue(
            field.name,
            options.map(option => ({ ...option }))
        );
    };

    handleBlur = (form, field) => {
        if (this.props.onBlur) {
            this.props.onBlur(form, field);
        }
        form.setFieldTouched(field.name);
    };

    renderInput = props => {
        const { isClearable = true, form, field, readOnly } = props;

        return (
            <ReactSelect
                {...this.props}
                isMulti
                isClearable={isClearable}
                allowSelectAll={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isDisabled={readOnly}
                components={{
                    Option,
                    MultiValueRemove
                }}
                options={props.options}
                onChange={options => this.handleChange(options, form, field)}
                onBlur={() => this.handleBlur(form, field)}
                value={field.value}
            />
        );
    };

    render() {
        return <Field {...this.props} render={props => this.renderInput(props)} />;
    }
}

export default ChecklistField;
