import update from 'immutability-helper';
import Promise from 'promise-polyfill';

export const dropHandler = (event, helperObject, toastError) => {
    if (helperObject.formObject && helperObject.formObject.form) {
        helperObject.formObject.form.setSubmitting(true);
    }

    let previews = helperObject.filePreviews.length > 0 ? [...helperObject.filePreviews] : [];
    const ids = helperObject.fileIDs.length > 0 ? [...helperObject.fileIDs] : [];
    const fileNamesContainer = helperObject.fileNames.length > 0 ? [...helperObject.fileNames] : [];
    const previewLength = previews.length;

    if (event) {
        const files = Array.from(event);

        const filePromise = Promise.allSettled(
            files.map((file, fileIndex) => {
                fileNamesContainer.push(file.name);

                helperObject.setFileNames(fileNamesContainer);

                return helperObject
                    .onUploadFile(file, helperObject.config, {
                        onUploadProgress: progressEvent => {
                            /*
								`onUploadProgress` occurs with each successful "upload" of the file. This means with larger files,
								this event will happen multiple times and the older code we have was pushing the same file to `filePreviews`
								multiple times. Instead we are no accessing the file at that index (fileIndex + length of already uploaded previews)
								to update it's information on progress.
							*/
                            const { loaded, total } = progressEvent;

                            previews[fileIndex + previewLength] = {
                                status: 'uploading',
                                filePreview: file,
                                responseFile: null,
                                percentage: parseInt((loaded / total) * 100 - 5)
                            };

                            helperObject.setFilePreviews(previews);
                        }
                    })
                    .then(response => {
                        const newFile = response.data.file ? response.data.file : { ...response.data };
                        ids.push(newFile.fileID);

                        let newState = [];

                        newState = previews.map(preview => {
                            if (preview.filePreview.name == file.name) {
                                preview.status = 'success';
                                preview.responseFile = newFile;
                                preview.percentage = 100;

                                return preview;
                            } else {
                                return preview;
                            }
                        });

                        previews = newState;

                        if (helperObject.clearPreviewsOnSave) {
                            helperObject.setFilePreviews([]);
                        } else {
                            helperObject.setFileIDs(ids);
                            helperObject.setFilePreviews(newState);
                        }

                        if (helperObject.fetchFiles) {
                            helperObject.fetchFiles();
                        }

                        if (ids.length == files.length) {
                            if (helperObject.formObject && helperObject.formObject.form) {
                                helperObject.formObject.form.setSubmitting(false);
                            }
                        }

                        if (helperObject.toastID) {
                            helperObject.notify.dismiss(helperObject.toastID);
                            helperObject.notify.success('File has been uploaded', { hideProgressBar: true });
                        }

                        return response;
                    })
                    .catch(error => {
                        let newState = [];

                        newState = previews.map(preview => {
                            if (preview.filePreview.name == file.name) {
                                preview.status = 'error';
                                preview.percentage = 100;

                                return preview;
                            } else {
                                return preview;
                            }
                        });

                        helperObject.setFilePreviews(newState);

                        toastError(error?.response?.request?.statusText ?? 'An unexpected error occurred.');
                    });
            })
        );

        filePromise.then(() => {
            if (helperObject.formObject && helperObject.formObject.form) {
                helperObject.formObject.form.setSubmitting(false);
            }

            const newState = previews.map(preview => {
                if (preview.status == 'error') {
                    preview.percentage = 'Failed';

                    return preview;
                } else {
                    return preview;
                }
            });

            helperObject.setFilePreviews(newState);

            if (helperObject.callback) {
                helperObject.callback(ids, helperObject.setFilePreviews);
            }
        });
    }
};

export const deleteFileHandler = (file, index, helperObject) => {
    let newState = [];
    let updateFileIDs = [];
    const removeFileName = helperObject.fileNames || [];
    removeFileName.splice(index, 1);

    helperObject.setFileNames(removeFileName);

    newState = update(helperObject.filePreviews, {
        $splice: [[index, 1]]
    });

    updateFileIDs = update(helperObject.fileIDs, {
        $splice: [[index, 1]]
    });

    helperObject.setFileIDs(updateFileIDs);
    helperObject.setFilePreviews(newState);

    if (helperObject.onDelete) {
        if (helperObject.config.path) {
            helperObject.onDelete(file[helperObject.config.path]?.fileID).then(() => {
                if (helperObject.callback) {
                    helperObject.callback(updateFileIDs);
                }
            });
        } else {
            helperObject.onDelete(file?.fileID).then(() => {
                if (helperObject.callback) {
                    helperObject.callback(updateFileIDs);
                }
            });
        }
    }

    if (helperObject.callback) {
        helperObject.callback(updateFileIDs);
    }
};
