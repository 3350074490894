import { useEffect, useRef, useState } from 'react';

import Generative from 'Lib/muiComponents/common/Generative';
import isEmpty from 'Lib/utilities/isEmpty';
import stripHtml from 'Lib/utilities/stripHtml';

import Field from './Field';

const TextAreaField = ({ id, className, generative, maxLength, minLength, placeHolder, required, readOnly, styleOverride, onBlur, onChange, ...rest }) => {
    const ref = useRef();
    const [position, setPosition] = useState({});

    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target) && !window.getSelection()?.toString()) {
            setPosition({});
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        if (generative) {
            document.addEventListener('selectionchange', handleSelection);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('selectionchange', handleSelection);
        };
    }, []);

    const isSelectionWithinBounds = () => {
        const selection = window.getSelection();

        if (!selection) return false;

        const range = selection.getRangeAt(0);
        const contentEditableDiv = ref.current;

        if (contentEditableDiv && contentEditableDiv.contains(range.startContainer) && contentEditableDiv.contains(range.endContainer)) {
            return true;
        }

        return false;
    };

    const handleChange = (e, form, field) => {
        if (onChange) {
            onChange(e, form, field);
        }

        form.handleChange(e);
    };

    const handleBlur = (e, form, field) => {
        const sanitizedValue = stripHtml(e.target.innerHTML);

        if (onBlur) {
            onBlur(e, form, field);
        }

        form.setFieldValue(field.name, sanitizedValue);
        form.handleBlur(e);
    };

    const handleSelection = () => {
        if (isSelectionWithinBounds()) {
            const selection = window.getSelection();

            if (isEmpty(selection.toString())) {
                setPosition({});

                return;
            }

            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            const scrollOffset = window.pageYOffset || document.documentElement.scrollTop;

            setPosition({
                top: rect.top + scrollOffset,
                left: rect.right + window.pageXOffset
            });
        }
    };

    const getFieldValue = value => {
        if (Array.isArray(value)) {
            return value.join('\n');
        }

        return value;
    };

    const renderInput = ({ form, field, error }) => {
        if (field.value == null) {
            field.value = '';
        } else {
            field.value = getFieldValue(field.value);
        }

        const attrs = {
            ...field,
            id,
            className,
            maxLength,
            minLength,
            required
        };

        if (attrs.className) {
            attrs.className = 'form-control';
        } else {
            attrs.className = 'form-control';
        }

        if (readOnly) {
            attrs.className = attrs.className + ' read-only';
        }

        if (error) {
            attrs.className = attrs.className + ' ' + 'is-invalid';
        }

        return generative ? (
            <>
                <div
                    {...attrs}
                    ref={ref}
                    style={{ minHeight: '250px', overflow: 'auto', ...styleOverride }}
                    placeholder={placeHolder}
                    readOnly={readOnly ? true : ''}
                    onBlur={e => handleBlur(e, form, field)}
                    contentEditable={readOnly ? false : 'plaintext-only'}
                    // This is needed since it's a contentEditable div (essentially a faux textarea) and we're controlling the value
                    suppressContentEditableWarning={true}
                >
                    {field.value}
                </div>
                <Generative field={field} form={form} position={position} onClose={() => setPosition({})} />
            </>
        ) : (
            <textarea
                {...attrs}
                style={{ minHeight: '250px', ...styleOverride }}
                placeholder={placeHolder}
                readOnly={readOnly ? true : ''}
                onChange={e => handleChange(e, form, field)}
                onBlur={e => handleBlur(e, form, field)}
            >
                {field.value}
            </textarea>
        );
    };

    return <Field {...rest} fast={false} render={renderInput} />;
};

export default TextAreaField;
