import { useEffect, useState } from 'react';
import { Col, Progress, Row } from 'reactstrap';

import Percent from 'Lib/components/common/Percent';

export const ShowFiles = props => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const width = document.getElementById('container')?.clientWidth;
        setWidth(width);
    });

    return <div id="container">{width <= 450 ? iterateFilePreviewsRows({ ...props }) : iterateFilePreviewsCols({ ...props })}</div>;
};

const iterateFilePreviewsRows = props =>
    props.filePreviews
        ? props.filePreviews.map((file, index) => (
              <Row key={index}>
                  <Col key={index}>{renderInfo(index, file, { ...props })}</Col>
              </Row>
          ))
        : null;

const iterateFilePreviewsCols = props => (
    <Row>
        {props.filePreviews
            ? props.filePreviews.map((file, index) => (
                  <Col key={index} md="4">
                      {renderInfo(index, file, { ...props })}
                  </Col>
              ))
            : null}
    </Row>
);

const renderInfo = (index, { filePreview, percentage, responseFile, status } = {}, props) => {
    const progressProps = {
        color: status == 'error' ? 'danger' : status == 'success' ? 'secondary' : 'primary',
        striped: status == 'success' ? false : true,
        animated: status == 'uploading' ? true : false
    };

    return (
        <div style={{ padding: '5px 10px' }}>
            <Progress key={index} {...progressProps} value={percentage} style={{ height: '100%' }}>
                <Row>
                    <Col md="12">
                        <div className="show-files-container">
                            <div className="file-info-container">
                                <div className="file-info">{renderThumbnail(filePreview).substr(0, 12)}...</div>
                                <div className="file-info">{status != 'error' ? status == 'uploading' ? <Percent value={percentage} decimalScale={0} /> : status : status}</div>
                            </div>
                            {percentage == 100 && status !== 'error'
                                ? props.handleDelete && (
                                      <div className="delete-button" onClick={() => props.handleDelete(responseFile, index)}>
                                          <i className="fas fa-times"></i>
                                      </div>
                                  )
                                : null}
                        </div>
                    </Col>
                </Row>
            </Progress>
        </div>
    );
};

const renderThumbnail = file => file.name;
